import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Motion Motion Detection Sensors",
  "path": "/Products/IN-Motion/500/Verkabelung/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Der IN-Motion 500 verfügt über eine Mikrowelle und einen passiven Infrarot-Bewegungsmelder. Es ist perfekt für die Outdoor-Überwachung geeignet.",
  "image": "../../P_SearchThumb_MotionDetector.png",
  "social": "/images/Search/P_SearchThumb_MotionDetector.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Motion_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-Motion 500 Motion Detection Sensors' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-Motion 500 comes with a microwave and a passive infrared motion sensor. It is the perfectly suited for outdoor detection.' image='/images/Search/P_SearchThumb_MotionDetector.png' twitter='/images/Search/P_SearchThumb_MotionDetector.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Motion/500/Wiring/' locationFR='/fr/Products/IN-Motion/500/Wiring/' crumbLabel="Wiring" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-motion-500",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-motion-500",
        "aria-label": "in motion 500 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion 500`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Diskutieren Sie diesen Artikel im `}<a parentName="p" {...{
          "href": "https://forum.instar.de/c/andere-produkte/7"
        }}>{`INSTAR Forum`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "wiring-connector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wiring-connector",
        "aria-label": "wiring connector permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wiring Connector`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "849px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/f96df/IN-Motion500_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC5ElEQVQ4y4WT22/TSBTG8zfywsMiISSkFQLKihd2xa1QRa22FJXtchMCiQeKAInLQ6XCUwMtpFc3aRzbM3E8tmfseDyp764vTYySVKByPRrNw5F+833nO5rSXhQyrDqMOY7ruJ6hItvAruMmaVr8rkpMR9svb8uVRW35k1ZdVZYq0sJrsPCGbdcyVUuRmipojxgAgFqtBgDgeb5er4dhOIA92lH4NWXlAxYhRprWRpam6gKPVVQURb/fH91JkmRZlud5EARRFPV6vQEcdG2mm2y91mlChKmB6fLC4uL8U7TO9cMod9x9x+2FEWOs2+3u7u5GUWRZlm3blNJS2JYf3Zwb+/PCg4kZFSIb0zuT05fPnTera/uOk1GaUdr3fcuyBEHgGw3bthVFkWW51WqVQg09Lv/719GTj6duoTZpa9bOu6XKs9cylAeGewe2gyBIhxXHcZqmo3FKWRZvvP14/FgZVD5hXkJcg/CgtcaBj9ViqJxTuu+6AIBmswkhlCQJQphl2QBOQn/u4fyRE9cnZuen7726cffl5P/Px6f+E1fXiyTNPT/3/P5eomO8ubkpSZIgCJIkMcYGcOh2/ynPnrvy5PT4i7HxZ2PXnp+69PToH2P8ynIRJ9kgMG8/CBzHYYyFYRjHsed5B6tKAv/O/Ydn/p44dal8dnzq9MXy2auT0zMzsLpaeH5ms9xmuesBAARB4DhOFEWe57e2tnzfL8W+h5ApAHOHV9c2JK7WrjeQKKGDwIbBfKksy5Ik+dIvRb7bgqoICC+oXK21vdNuijrfgLIIhmn3in6/OPzEVzj2PcukOrZ0bGFCdd3Chg0AUmDre+VvOqXI94iOMaF4yOvYMkzGN4ACfgwf+hix73VMy+h0iWGPjmntik34M+XDcOARBekqIcQiuGMYtGMyDQ08/B5OolCornCV980NrrnJ1avrsCESlYR+MBrxV/BgAUliE4JlWQeSBiE1Ontx/GvNEfwZdf/CpUsVfQwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a902d7a6206df6ee7ab04b1500f32f3/e4706/IN-Motion500_05.avif 230w", "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/d1af7/IN-Motion500_05.avif 460w", "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/1450a/IN-Motion500_05.avif 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a902d7a6206df6ee7ab04b1500f32f3/a0b58/IN-Motion500_05.webp 230w", "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/bc10c/IN-Motion500_05.webp 460w", "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/51e4d/IN-Motion500_05.webp 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a902d7a6206df6ee7ab04b1500f32f3/81c8e/IN-Motion500_05.png 230w", "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/08a84/IN-Motion500_05.png 460w", "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/f96df/IN-Motion500_05.png 849w"],
              "sizes": "(max-width: 849px) 100vw, 849px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a902d7a6206df6ee7ab04b1500f32f3/f96df/IN-Motion500_05.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`V+`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Netzteil +`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anschluss für die 12V-Stromversorgung +`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`V-`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Netzteil -`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anschluss für die 12V-Stromversorgung -`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`C & NC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relais Jumper `}<strong parentName="td">{`NC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Damit können Sie das Relais auf `}<em parentName="td">{`Normally Closed`}</em>{` (C & NC) setzen. Im NC-Modus kann eine Unterbrechung der Verbindung zum Sensor, z. Wenn es beschädigt ist, führt dies zu einem Alarm. `}<strong parentName="td">{`N.C.`}</strong>{` ist der Standardmodus.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`C1/T2 & NO1`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relais Jumper `}<strong parentName="td">{`NO`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Damit können Sie das Relais auf `}<em parentName="td">{`Normally Open`}</em>{` (C1 / T2 & NO1) einstellen. Im NO-Modus wird durch eine Unterbrechung der Verbindung zum Sensor kein Alarm ausgelöst. Der `}<strong parentName="td">{`N.O.`}</strong>{` Modus wird nicht empfohlen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`C1/T2 & T1`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sabotage-Schalter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hierbei handelt es sich um einen externen Sabotageschalter, der einen Alarm auslöst, falls der Sensor geöffnet wird, um Manipulationen zu vermeiden.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "verbinden-sie-den-in-motion-500-mit-ihrer-instar-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#verbinden-sie-den-in-motion-500-mit-ihrer-instar-kamera",
        "aria-label": "verbinden sie den in motion 500 mit ihrer instar kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verbinden Sie den IN-Motion 500 mit Ihrer INSTAR-Kamera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "824px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/c1c45/IN-Motion500_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACfUlEQVQoz42SS2sTURTH57u5cCP4HVRw5wdwV1eCKFVxI1pdSAURqUppaQtNU22bxjSTSTKZaRIyd2buPDLv9+s+JE1ai1bxbC7ncH78z/mfy9D/jqqqZADqtVoYhpRSQggzK5NfHYQQhDFCCGM8qxMyr9qWVd+rfVlb+76/nybJvJmZPxjjPC/iOI2ixPdDzwsc18/zAlHqep4OVRXIGoRxHF2SoQzBOAyCKIylsaTIqu8HtuValmMYU9Ow1F7PkRVMyLkGCVXoDUdxnMzGzrLMtl3XCxSgaoqmQVOWIABQmqijEYi6/eyokfX5QlGKMPJ0U2+21C4PGi0viJgojFRZ06AhS3AoTvjekGMFjh2wrX63zZcVogjlEykfjWNjKnJ8ffVTc6Mm7jcUxWRcxzsVxiI/6rFC54Tn2gNxMAITxba9AhNK8MzDmddl5+DH0oPnj5aevVleWV5++371K+O5Pt8VhsIYTBTHcrI0IxjP1ouitNVeHOAMz7L88ZPX12/euXbj1u27909PJwzGOEszfAYQSouiCv0oKapwLJkvVxa20oV8mmXfDpqbW3uGaS1OVZYoCGLb9jXNAsAAwNA027P9COolwuTcanI+wkXKlGWl6zZULQhtQ7N1aCqyznVEURg7ju84fllWl4EKoWr+hShlXDcAwBiKgD3ht3ebH9cPP28ct1l+OnXSJLtM/hmMIhvsCc91hONa/eGr9XtPd168r5kQBp6TJHFZlnORq2FVMXa2D2q7x0eHXLvF9zuC2BP63IDnBqo4dB0vTtK/wpRShFCSpIEfWlNXhSaQNFk1xQYrvfsQJnkcxv+Cf48zS5Hj+JtbF+mV8E+IQHLHsBU6rAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/e4706/IN-Motion500_09.avif 230w", "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/d1af7/IN-Motion500_09.avif 460w", "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/b2c53/IN-Motion500_09.avif 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/a0b58/IN-Motion500_09.webp 230w", "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/bc10c/IN-Motion500_09.webp 460w", "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/5758c/IN-Motion500_09.webp 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/81c8e/IN-Motion500_09.png 230w", "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/08a84/IN-Motion500_09.png 460w", "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/c1c45/IN-Motion500_09.png 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fe2faff6cbd115f3d9d71e1fab1e1d60/c1c45/IN-Motion500_09.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Schritt 1:`}</strong>{`  Öffnen Sie den IN-MOTION 500 mit einem Philips-Schraubendreher, um die Taste unten zu drücken. Trennen Sie die vordere Abdeckung von der hinteren Abdeckung.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "824px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9852531e4cd6d46fb0ead16dc4b87430/c1c45/IN-Motion500_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB30lEQVQoz6WTW3PTMBCF/f9/DAwdXuGFoWkydBq3aWNH95VkSbZ8qXOx49hOaQpMBwoD5Xtaze6ZI+0cBcf/IHiFpu97Z+0wDP8mHsfxeDxWVfnu7ZvtZhP81Hjx+KN+Kqwx78/OmqZ5jbMxZja9+Hbt8UTf988dyqou6/Vm1263TdM0h0PfD8PTQFHkuc8eF1bX9fzEZDJBCIVhGMfxrmkpA0GYBC20pVwJ5aROVZK6rKQchNTt/hAMw9B8p+u6tm37/pDlFQglwht+c0uFAp0KaZkwmCkGyeTzxWx2CTIJXnyVz0tEBFnR1YouY4yoJEwRpjDXjMLsw8fwfCrgJLbGxPFSG5v6vCir9XpzX6/TLLep1yaTOuWQIALLmN1F7DaiywX6crkwzgd5UUyn0/nVfHV9gxe3MRYRYggTTLkABTqROjEua/f7pm3v67XPK209KPe47TAMP51PuNB8cScjxKWhQjEhmVAMNEGYzK/xXdTs979mIUizAlFgkDAwXFouLYMElAPtIEmBSx2j5fzaWOe9FwDpCedOzsMwdl2327V1vc6LyqW5NplQhnKNKWAmI8KvFrF1aRxHYRhKKQkhGOPfZnsYxkPft/tus9l6X/i8/KtfNf4xm8/5Kn4AEbeG/stNxZcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9852531e4cd6d46fb0ead16dc4b87430/e4706/IN-Motion500_10.avif 230w", "/en/static/9852531e4cd6d46fb0ead16dc4b87430/d1af7/IN-Motion500_10.avif 460w", "/en/static/9852531e4cd6d46fb0ead16dc4b87430/b2c53/IN-Motion500_10.avif 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9852531e4cd6d46fb0ead16dc4b87430/a0b58/IN-Motion500_10.webp 230w", "/en/static/9852531e4cd6d46fb0ead16dc4b87430/bc10c/IN-Motion500_10.webp 460w", "/en/static/9852531e4cd6d46fb0ead16dc4b87430/5758c/IN-Motion500_10.webp 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9852531e4cd6d46fb0ead16dc4b87430/81c8e/IN-Motion500_10.png 230w", "/en/static/9852531e4cd6d46fb0ead16dc4b87430/08a84/IN-Motion500_10.png 460w", "/en/static/9852531e4cd6d46fb0ead16dc4b87430/c1c45/IN-Motion500_10.png 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9852531e4cd6d46fb0ead16dc4b87430/c1c45/IN-Motion500_10.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Schritt 2:`}</strong>{`  Drücken Sie mit einem Schraubendreher eines der beiden Löcher UND führen Sie die 4-adrigen Kabel des 5-Meter-Kabels in dieses Loch ein und fahren Sie mit dem nächsten Schritt fort.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "824px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/408629196f696fbde0f629be0000291f/c1c45/IN-Motion500_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADMklEQVQoz3WS2U8TYRTF+weYmOiTb/qAJuKWiBsxqLgbTMQoRo0IioiCLeACCIWAUKoNxQgUFCyoYAEhRgiEB0KsMUYTBaUylA5DO53OtDPTxUpn2lk/U0hAXM7DzX353Zx7z1UAACYc9ju65rzK+uJaY56mbvnKlWmqknJDe3lj+y1ds/ZZD0IQAABZlsFSKUSBTdVX7azo3pDTsCX/yf7KzhRV7qYCQ9LDwXjNq/jqnoOtbytMLwnUMWWzQRDkdDoBAJIkReFIhMnQl5UZGjNL7xledL2sqTO1PU/R6Wu6Ootqm/K1+pr+wQpjPYogw8PDZrPZarUuuFBIIr/jzLGNJ7OvZ1cWpZyL27o18YoySalRbN+5Kvnk5ktFy3YfSL1xmQuHXTiOoqjdbidJEsOwUCikAADoG2o1uobuZlNBSVFavrJUW9nU0ZOpzMktu1uoqb58Nf1Vb7cgiBRF+f1+mqaDwSBBECzLRmEgg9nZH3TAy7LMT7//R8CHztgCXjrgpQJeD8sy4D+KwhzPewjC7cLcBD49DcOw7evY6IRlHJm2OewzOI5Jkji/57wWd57rJNrrYxgGyILAcbIsSSIvS9Eq8JwoLiF/DywKf/w2dfDQYVNX79DHqYH34wPvxvrfjpo/WT58tb7/An3+bptl2Dn+L9sCE0wofbpaZSwv1p5vHFp3f2hNVX9MxettNYN7mkYSDMO7mkYe9fYAnmPDkWAwGDW4YPun33u6rPq0pqXqpvpaa4u2zZRu6FB3D94ydhV09Jd0DqTXtZU0PJy0jL/p6zObzeFweBGOcLPbVWmHk5Wqo2dWZBVm1RuTbz+IzciLPZsVl3lzr6p8bfL5G+o7bCg0abVCEOTz+Xie5ziO53mFIHBJygu5xY9TMnK3pF44olbvS78Yk7R3/dHETccTE06diN0Xr3ukjYQjLtyFoigxJwiCaJqOHowNMZjH48Bc6AwMW7877AgC25z2GTsyDdsmMSfK8fwfUS1eW5RED4G7XRjtpVHUgSDw2Ohni+UbgsBO1EGSnj+iWhgxn7NMUxTlcdMU6SZwmiIxJ0p63KSbcBO4z+eN/uC/PuwXNfXnxz+Fzm4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/408629196f696fbde0f629be0000291f/e4706/IN-Motion500_11.avif 230w", "/en/static/408629196f696fbde0f629be0000291f/d1af7/IN-Motion500_11.avif 460w", "/en/static/408629196f696fbde0f629be0000291f/b2c53/IN-Motion500_11.avif 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/408629196f696fbde0f629be0000291f/a0b58/IN-Motion500_11.webp 230w", "/en/static/408629196f696fbde0f629be0000291f/bc10c/IN-Motion500_11.webp 460w", "/en/static/408629196f696fbde0f629be0000291f/5758c/IN-Motion500_11.webp 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/408629196f696fbde0f629be0000291f/81c8e/IN-Motion500_11.png 230w", "/en/static/408629196f696fbde0f629be0000291f/08a84/IN-Motion500_11.png 460w", "/en/static/408629196f696fbde0f629be0000291f/c1c45/IN-Motion500_11.png 824w"],
              "sizes": "(max-width: 824px) 100vw, 824px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/408629196f696fbde0f629be0000291f/c1c45/IN-Motion500_11.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Schritt 3:`}</strong>{`  Lösen Sie die Schrauben von V +, V-, C und NC (oder C1 / T2 und NO1) und führen Sie den roten Draht an V +, den schwarzen an V- und die beiden Signalkabel an die NC- oder NO-Anschlüsse an.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/67fe0/IN-Motion500_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQY02P4/////ddfJp18+u//v//////79/f///+fXzy5OLXr/PK5n759BguCpP7//3/p4bX9N45eenL10Zsn////Z/j////1R7cWHdv++PWj//////7z5////7svHhPyNhCMtl5/6TBI8O+fv3///v//z6sxyqojxKDUqWxBPVTz60+v9tze/ujTg//////5C9J84P416QnZcn05O6+fBQv+hdi8+NCaio1d9au77ry4B9X86dSJjdMbLm1Y9PfsOYiiP3///vr39x/YwT/A4M2bN+/evfv44fPbN+8+vPvw+cOnr1+/gjS/XL06zdWrM7fo05q1/8GW/Pz58/69e4cPHlw0f/69O3eWL1+uoiBnaKAX6WUR6mWmbawrISnW3dUJ0vzz9u3elIxpOQVfLl2C2Lxt6xYtDQ1xEZGDBw78//9/4YIFGqrKrk52QW624aGuiQVJltZm06dOZfgDDqHmts6wiLjff/7+BwfsqcP7/VwdvD09//////fv358/f378CHLn12/fX7150zSzYcvhzSA/g4Px/7Ejxyb0TIDHyvdvX8+cPHH92jXkeIIwvn3/NmP9tMMXQbEAAD4keDNdbHOvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0b9a915c6295147fd743d2b7d3eafe9/e4706/IN-Motion500_12.avif 230w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/d1af7/IN-Motion500_12.avif 460w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/7f308/IN-Motion500_12.avif 920w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/4293a/IN-Motion500_12.avif 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d0b9a915c6295147fd743d2b7d3eafe9/a0b58/IN-Motion500_12.webp 230w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/bc10c/IN-Motion500_12.webp 460w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/966d8/IN-Motion500_12.webp 920w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/41823/IN-Motion500_12.webp 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0b9a915c6295147fd743d2b7d3eafe9/81c8e/IN-Motion500_12.png 230w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/08a84/IN-Motion500_12.png 460w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/c0255/IN-Motion500_12.png 920w", "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/67fe0/IN-Motion500_12.png 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d0b9a915c6295147fd743d2b7d3eafe9/c0255/IN-Motion500_12.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Schritt 4:`}</strong>{`  Ziehen Sie die Schrauben an und schließen Sie das Gehäuse. Befestigen Sie das Gehäuse an der Halterung. Verbinden Sie den Netzstecker mit dem mitgelieferten 12V / 1A-Netzteil. Verbinden Sie die beiden Signalkabel mit dem Alarmeingang der Kamera. Der Anschluss für den Alarmeingang Ihrer Kamera kann je nach Kameramodell anders aussehen. Bitte beziehen Sie sich auf das Benutzerhandbuch Ihrer IP-Kamera, um die richtigen E / A-Anschlüsse zu ermitteln. Sie können beim Anschließen des Kabels eine beliebige Polarität wählen.`}</p>
    <h3 {...{
      "id": "technische-spezifikationen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#technische-spezifikationen",
        "aria-label": "technische spezifikationen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technische Spezifikationen`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Sensormodell`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`INSTAR IN-Motion 500 Bewegungsmelder mit zwei Mikrowellen und PIR`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Betriebsspannung`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`D.C. 12V`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Verbrauch`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62mA @ DC12V`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Relaisausgang`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NC / NO, DC 25V / 100mA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Erfassungswinkel`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Detektionsbereich`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 m x 12 m`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Startzeit`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 Sekunden (Aufwärmzeit bis zu 10 Minuten bei Temperaturen unter -10 ° C!)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`RF-Interferenzkompensation`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10MHz 1GHz 20V/m`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Alarmanzeige`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rot und Blau LED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Alarmausgabe`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N.C. or N.O., DC 25 V / 100 mA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tamper Out`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N.C., DC 25 V / 100 mA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensor`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pyroelektrischer Zwei-Elemente- und Mikrowellensensor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Umweltfeuchtigkeit`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95% RH (nicht kondensierend)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Arbeitstemperatur`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C to +50°C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Installationsmodus`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wandhalterung`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mounting-Optionen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decken- und Wandhalterung`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Größe (H/W/D)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 mm / 45mm / 136 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Gewicht`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kompatibel mit`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IN-2905 V2, IN-2907, IN-2908, IN-3001, IN-3005, IN-3010, IN-3010 PoE, IN-3011, IN-4009, IN-4010, IN-4011, IN-5907HD, IN-5907HD PoE, IN-6011HD, IN-6012HD, IN-6012HD PoE, IN-7011HD, IN-7011HD PoE`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      